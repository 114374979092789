.intro,
footer {
  width: 400px;
  margin: auto;
}

.list {
  width: 430px;
  margin: 10px auto;
  padding: 15px;
}
.item {
  border: 1px solid black;
  overflow: hidden;
  transform-origin: 30% 50% 0px;
  background: white;
  max-height: 100%;

}
.item .dragHandle {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  cursor: move;
  width: 20px;
  height: 16px;
  background: url('grippy.png') 30% 50% no-repeat no-repeat;
}
.item:hover .dragHandle,
.item.dragged .dragHandle {
  visibility: visible;
}
.item h2 {
  margin: 0;
}
.item .subtitle {
  font-weight: bold;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}