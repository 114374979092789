:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #009ef7;
    --toastify-color-success: #50cd89;
    --toastify-color-warning: #ffc700;
    --toastify-color-error: #f1416c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
  
    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;
  
    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;
  
    /* Used only for colored theme */
    --toastify-text-color-info: #009ef7;
    --toastify-text-color-success: #50cd89;
    --toastify-text-color-warning: #665000;
    --toastify-text-color-error: #f1416c;
    --toastify-text-color-default: #5b5c60;
  
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
  
    /* 
    Used when no type is provided
    toast("**hello**") 
    */
    --toastify-color-progress-light: linear-gradient(
      to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55
    );

    /* Used when no type is provided */
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);

    /* CUSTOM STYLE */
    --toastify-background-color-info: #f1faff;
    --toastify-background-color-success: #e8fff3;
    --toastify-background-color-warning: #fff4cc;
    --toastify-background-color-error: #fff5f8;
    --toastify-background-color-default: #fafafc;
  }